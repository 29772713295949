import React from 'react'
import { Router } from '@reach/router'
import Layout from 'src/theme/Layout'
import VerifyEmail from 'src/features/auth/VerifyEmail'
import PATHS from 'src/utils/paths'
import styled from 'styled-components'
import Metadata from 'src/components/Metadata'
import { META } from 'src/utils/seo'

export const Verify = () => (
  <Layout>
    <Metadata meta={META.landingPage} />
    <VerifyContainer>
      <Router>
        <VerifyEmail path={PATHS.verifyEmail} />
      </Router>
    </VerifyContainer>
  </Layout>
)

export default Verify

const VerifyContainer = styled.div`
  height: 100%;
`
