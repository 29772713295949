import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { verifyUserEmailRoutine } from 'src/features/auth/duck/actions'

import Loader from 'src/components/atoms/Loader'

export const VerifyEmail = props => {
  const { id, token } = props
  //  REDUX
  const dispatch = useDispatch()
  const verifyUserEmail = useCallback(
    payload => dispatch(verifyUserEmailRoutine(payload)),
    [dispatch]
  )

  useEffect(() => {
    verifyUserEmail({ id, token })
  }, [])

  return (
    <ProgressContainer>
      <Loader />
    </ProgressContainer>
  )
}

export default VerifyEmail

const ProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
